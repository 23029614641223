<template>
    <div>
        <nav
            class="navbar navbar-expand-md navbar-light top-navbar"
            id="topbar-content"
        >
            <div class="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#" class="navbar-brand">Dashboard</a></li>
                        <li class="breadcrumb-item active d-flex justify-content-center align-items-center " aria-current="page">Social Network Links</li>
                    </ol>
                </nav>
            </div>
        </nav>
        <div class="container">
            <button
                @click="addLink"
                type="button"
                class="btn btn-primary btn-block mb-5"
            >
                Add New Social Link
            </button>

            <draggable
                v-model="links"
                :options="{ animation: 350 }"
                handle=".handler"
            >
                <div
                    class="card mb-3"
                    v-for="(link, index) in links"
                    :key="index"
                >
                    <div class="card-body m-0 p-1">
                        <div class="row">
                            <div class="col-auto handler">
                                <span
                                    class="fe fe-more-vertical text-muted"
                                    style="font-size: 22px"
                                ></span>
                            </div>
                            <div class="col-auto icon-box border-none" @click="openIconModal(link)">
                              <img v-if="link.icon" :src="getIconUrl(link.icon)" alt="">
                              <img v-else :src="getIconUrl('Group 2')" alt="">
                            </div>
                            <div class="col content">
                                <div class="row">
                                    <div class="col d-flex align-items-center">
                                        <input
                                            v-model="link.link"
                                            @keyup="patchLink(link, index)"
                                            type="text"
                                            class="form-control form-control-flush"
                                            placeholder="http://url"
                                        />
                                    </div>
                                    <div class="col-auto d-flex flex-column align-items-end">
                                        <button
                                            @click="(link.active = !link.active); patchLink(link, index);"
                                            class="btn btn-rounded-circle btn-white border-0 mb-2"
                                        >
                                            <span
                                                class="fe fe-eye"
                                                v-if="link.active"
                                            ></span>
                                            <span
                                                class="fe fe-eye-off"
                                                v-else
                                            ></span>
                                        </button>
                                        <confirmable-button
                                            @confirm="removeLink(link)"
                                        >
                                            <i class="fe fe-trash-2"></i>
                                        </confirmable-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </draggable>

            <b-modal id="modal-1" centered title="Select Icon" v-model="iconModal" size="sm">

                <div class="feathers-icons">
                    <div class="row">
                      <div class="col icon-box" v-for="(icon, index) in brands" :key="index" @click="setIcon(icon)">
                        <img :src="getIconUrl(icon)" alt="">
                      </div>
                    </div>
                </div>

                <template #modal-footer>
                    <div class="w-100">
                        <b-button
                            variant="primary"
                            size="sm"
                            class="float-right"
                            @click="iconModal=false"
                        >
                            Close
                        </b-button>
                    </div>
                </template>
            </b-modal>

        </div>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
import ConfirmableButton from "@/components/ConfirmableButton";
import debounce from 'lodash/debounce';

export default {
    components: {
        draggable,
        ConfirmableButton,
    },

    data() {
        return {
            iconModal: false,
            currentLink: {},
            params: {
                query: {
                    $limit: 1000,
                    $sort: { priority: 1, createdAt: -1 },
                    type: { $in: ['socialLink'] },
                },
            },
            icons: [
              'amazon',
              'android-store',
              'apple-appstore',
              'apple-music',
              'apple-podcast',
              'band-camp',
              'facebook',
              'instagram',
              'linkedin',
              'patreon',
              'paypal',
              'pinterest',
              'poshmark',
              'signal',
              'snapchat',
              'sound-cloud',
              'spotify',
              'telegram',
              'twitch',
              'twitter',
              'whatsapp'
            ],
            font_icons: [
                'twitter',
                'facebook',
                'linkedin',
                'youtube',
                'spotify',
                'yandex',
                'tiktok',
                'whatsapp',
                'telegram',
                'apple',
                'microsoft',
                'airbnb',
                'google-play',
                'app-store-ios',
                'bitcoin'
            ],
            brands: [
              'Group 2',
              'Group 3',
              'Group 4',
              'Group 5',
              'Group 6',
              'Group 7',
              'Group 8',
              'Group 9',
              'Group 10',
              'Group 11',
              'Group 12',
              'Group 13',
              'Group 14',
              'Group 15',
              'Group 16',
              'Group 17',
              'Group 18',
              'Group 19',
              'Group 20',
              'Group 21',
              'Group 22',
              'Group 23',
              'Group 24',
              'Group 25',
              'Group 26',
              'Group 27',
              'Group 28',
            ]
        };
    },
    created() {
        //this.params.query.userId = this.user.userId;
        //this.find(this.params);
    },
    watch: {
    },
    computed: {
        ...mapState('auth', {
            user: 'payload',
        }),
        ...mapState('links', {
            isUserLoading: 'isFindPending',
        }),
        ...mapGetters('links', {
            findLinksInStore: 'find',
        }),
        links: {
            set(value) {
                value.forEach((item, index) => {
                    item.priority = index;
                    this.patch([item._id, item])
                });
                //console.log(value)
            },
            get() {
                return this.findLinksInStore(this.params).data;
            }
        },
    },

    methods: {
        ...mapActions('links', ['find', 'create', 'patch', 'remove']),
		patchLink: debounce(function (link, index) {
            if (!link._id) return;
            this.errors = null;
            let data = {
                title: link.title,
                link: link.link,
                active: link.active,
                icon: link.icon,
                type: 'socialLink',
                userId: this.user.userId,
            }
            this.patch([link._id, data, {}])
                .then(() => {
                    this.$toasted.global.success({
                        message: this.$t("Link updated"),
                    });
                    this.currentLink = {}
                })
                .catch((errors) => {
                    this.$toasted.global.error({
                      message: this.$t(errors.message),
                    });
                    console.log(errors);
                    this.errors = errors.errors;
                });
        }, 1000),

        addLink() {
            this.errors = null;
            let data = {
                link: 'https://',
                type: 'socialLink',
                icon: 'Group 2',
                userId: this.user.userId
            }
            this.create([data])
                .then( () =>{
                    this.$toasted.global.success({
                        message: 'Link Added',
                    });
                })
                .catch((errors) => {
                    this.$toasted.global.error({
                      message: this.$t(errors.message),
                    });
                    console.log(errors);
                    this.errors = errors.errors;
                });
        },
        removeLink(link) {
            this.errors = null;
            this.remove([link._id])
                .then( () =>{
                    this.$toasted.global.success({
                        message: 'Link Removed',
                    });
                })
                .catch((errors) => {
                    this.$toasted.global.error({
                      message: this.$t(errors.message),
                    });
                    console.log(errors);
                    this.errors = errors.errors;
                });
        },
        openIconModal(link) {
            this.currentLink = link;
            this.iconModal = true;
        },
        setIcon(icon) {
            this.currentLink.icon = icon;
            this.patchLink(this.currentLink);
            this.iconModal = false;
        },
        getIconUrl(icon) {
          if ( this.brands.includes(icon) )
            return require('../../assets/brands/'+ icon +'.svg');
        }
    },
};
</script>

<style lang="scss">
.handler {
    cursor: move;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 10px;
}
.top-navbar {
    height: 70px;
    margin-bottom: 30px;
}
.modal-footer {
    padding: 0.5rem;
}
.icon-box {
    img {
      width: 35px;
      height: 35px;
    }
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    transition: .25s linear;
    font-size: 28px;
    margin: auto;
    &:hover {
        background: #e3ebf6;
    }
}
.border-none {
    border: none;
    &:hover {
        background: transparent;
    }
}
</style>
